<template>
    <div id="app">
        <!-- :key="$route.fullPath" 解决了路由前缀相同时跳转不刷新 -->
        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
        <keep-alive>
            <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
        </keep-alive>
    </div>
</template>
<script>
    export default{
        name:  'App',
        metaInfo() {	// vue-meta-info 是一个基于vue 2.0的插件，更好的管理你的 app 里面的 meta 信息。你可以直接 在组件内设置 metaInfo 便可以自动挂载到你的页面中。
            return {
                // title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
                title: process.env.VUE_APP_TITLE,
                titleTemplate: title => {
                    // return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
                    return title
                }
            }
        },
        created(){
            // if(this.$store.getters.NavList.length==0){
            //     this.$store.dispatch("commonData/getNavList");
            // }
        }
    }
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from '../store'

const routes = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/Index.vue'),
        meta:{
            keepAlive:true
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
    },
    {
        path: '/company',
        name: 'company',
        component: () => import('../views/organization.vue')
    },
    {
        path: '/newsCenter',
        name: 'newsCenter',
        component: () => import('../views/NewsCenter.vue')
    },
    {
        path: '/newlist',
        name: 'newlist',
        component: () => import('../views/NewsList.vue')
    },
    {
        path: '/newDetail',
        name: 'newDetail',
        component: () => import('../views/NewsDetail.vue'),
        
    },
    {
        path: '/zixun',
        name: 'zixun',
        component: () => import('../views/Professionalzixun.vue')
    },
    {
        path: '/open',
        name: 'open',
        component: () => import('../views/Informationopen.vue')
    },
    {
        path: '/jianyi',
        name: 'jianyi',
        component: () => import('../views/Jianyi.vue')
    },
    {
        path: '/brand',
        name: 'brand',
        component: () => import('../views/brandactive.vue')
    },
    {
        path: '/dang',
        name: 'dang',
        component: () => import('../views/dangjian.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/ProductCenter.vue')
    }
]


// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(err => {
        console.log(err)
    })
}

const router = new VueRouter({
    mode: 'history',
    routes:routes,
    scrollBehavior (to, from, savedPosition) {      // 解决问题：页面跳转到另一个页面， 新进的页面不是在顶部
        // 始终滚动到顶部
        return { x: 0, y: 0 }
    }
})

export default router
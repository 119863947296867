import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import dict from './modules/dict'
// import user from './modules/user'
// import tagsView from './modules/tagsView'
// import permission from './modules/permission'
// import settings from './modules/settings'
import customdata from './modules/customdata'
import commonData from './modules/commonData'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    dict,
    // user,
    // tagsView,
    // permission,
    // settings,
    customdata,
    commonData
  },
  getters
})

export default store

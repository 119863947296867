const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  dict: state => state.dict.dict,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  userr: state => state.user.userr,
  userarealevel: state => state.user.userarealevel,
  tishiruopassword:state => state.user.tishiruopassword,
  permission_routes: state => state.permission.routes,
  topbarRouters:state => state.permission.topbarRouters,
  defaultRoutes:state => state.permission.defaultRoutes,
  sidebarRouters:state => state.permission.sidebarRouters,
  
  NationData:state => state.customdata.NationData,
  XihuaTwoData:state => state.customdata.XihuaTwoData,
  XihuaOneData:state => state.customdata.XihuaOneData,
  JobType:state => state.customdata.JobType,
  OddJobTypa:state => state.customdata.OddJobTypa,
  IndustryData:state => state.customdata.IndustryData,
  RegionLevel:state => state.customdata.region_level,
  RegionId:state => state.customdata.region_Id,
  
  
  slideList:state => state.commonData.slideList
  
}
export default getters

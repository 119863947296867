<template>
    <div class="">
        <div id="banner01" class="ban02">
            <el-carousel height="140px">
                <el-carousel-item v-for="(item,index) in slideData" :key="index">
                    <img class="slidimg" :src="item.imgUrl?baseUrl+item.imgUrl:defaultimg" :alt="item.title" />
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
    export default {
        name: "slider",
        components: {},
        data() {
            return {
                // slideData:[{title:'',image:require('../../../assets/images/136.png'),url:''},{title:'',image:require('../../../assets/images/138.png'),url:''},
                //     {title:'',image:require('../../../assets/images/139.gif'),url:''}],
                slideData:[],
                defaultimg:require('../../../assets/images/136.png'),
                baseUrl:''
            }
        },
        methods: {
            
        },
        created() {
            this.slideData = this.$store.getters.slideList;
            this.baseUrl = this.$VUE_APP_API
        },
        mounted() {
            // this.toggleStickyHeader();
        },
    }
</script>

<style scoped lang="scss">
    .ban02 {
        
        .el-carousel{
            width: 100%;
            height: 140px;
            .el-carousel-item{
                width: 100%;
                height: 140px;
            }
        }
        
        .slidimg{
            width: 1200px;
            height: 140px;
        }
    }
    
    
    
</style>
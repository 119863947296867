
// import {
//     getAreaListTree
// } from "@/api/common"; // 获取区域数据

// import {
//     getPositionTree,
//     getOddPositionTree,
//     getIndustry
// } from "@/api/service/cate"; // 职位类型，  零活类型

import Cookies from 'js-cookie'

const state = {
    NationData:Cookies.get('NationData')?JSON.parse(Cookies.get('NationData')):[] || [],         // 全国省市县数据
    XihuaTwoData:Cookies.get('XihuaTwoData')?JSON.parse(Cookies.get('XihuaTwoData')):[] ||[],      // 西华县数据，两级
    XihuaOneData:Cookies.get('XihuaOneData')?JSON.parse(Cookies.get('XihuaOneData')):[] ||[],      // 西华县数据，两级
    
    IndustryData:Cookies.get('IndustryData')?JSON.parse(Cookies.get('IndustryData')):[] ||[],       // 行业类型
    JobType:Cookies.get('JobType')?JSON.parse(Cookies.get('JobType')):[] ||[],                // 获取职位类型
    OddJobTypa:Cookies.get('OddJobTypa')?JSON.parse(Cookies.get('OddJobTypa')):[] ||[],          // 获取零活类型
    
    region_level:3,        // 0-全国 1-省级 2-市级 3-县级
    region_Id:411622      // 当前区域行政id
}

const mutations = {
    SET_NATION: (state, data) => {
        state.NationData = data;
        Cookies.set('NationData', JSON.stringify(data))
    },
    SET_XIHUATWO: (state, data) => {
        state.XihuaTwoData = data;
        Cookies.set('XihuaTwoData', JSON.stringify(data))
    },
    SET_XIHUAONE: (state, data) => {
        state.XihuaOneData = data;
        Cookies.set('XihuaOneData', JSON.stringify(data))
    },
    SET_JOBTYPE: (state, data) => {
        state.JobType = data;
        Cookies.set('JobType', JSON.stringify(data))
    },
    SET_ODDJOBTYPA: (state, data) => {
        state.OddJobTypa = data;
        Cookies.set('OddJobTypa', JSON.stringify(data))
    },
    SET_Industry: (state, data) => {
        state.IndustryData = data;
        Cookies.set('IndustryData', JSON.stringify(data))
    },
}

const actions = {
    // // 全国省市县数据
    // getNationData({
    //     commit,
    //     state
    // }) {
    //     return new Promise((resolve, reject) => {
    //         getAreaListTree({
    //             deep: 3,
    //             pid: 0
    //         }).then(res => {
    //             commit('SET_NATION', res.rows)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    // 
    // getXihuaTwoData({
    //     commit,
    //     state
    // }) {
    //     return new Promise((resolve, reject) => {
    //         getAreaListTree({
    //             deep: 2,
    //             pid: state.region_Id
    //         }).then(res => {
    //             commit('SET_XIHUATWO', res.rows)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    // // 西华县下一级数据
    // getXihuaOneData({
    //     commit,
    //     state
    // }) {
    //     return new Promise((resolve, reject) => {
    //         getAreaListTree({
    //             deep: 1,
    //             pid: state.region_Id
    //         }).then(res => {
    //             commit('SET_XIHUAONE', res.rows)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    // // 获取职位类型
    // getJobType({
    //     commit,
    //     state
    // }) {
    //     return new Promise((resolve, reject) => {
    //         getPositionTree({}).then(res => {
    //             commit('SET_JOBTYPE', res.rows)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    // // 获取零活职位类型     // 剔除大学生兼职
    // getOddJobTypa({
    //     commit,
    //     state
    // }) {
    //     return new Promise((resolve, reject) => {
    //         getOddPositionTree({}).then(res => {
    //             let arr = res.rows;
    //             let arr2 = arr.filter((item)=>{
    //             	if(item.id != 12){
    //             		return item;
    //             	}
    //             })
    //             commit('SET_ODDJOBTYPA', arr2)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    // // 获取零活职位类型
    // getIndustryData({
    //     commit,
    //     state
    // }) {
    //     return new Promise((resolve, reject) => {
    //         getIndustry({}).then(res => {
    //             commit('SET_Industry', res.rows)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // toggleDevice({
    //     commit
    // }, device) {
    //     commit('TOGGLE_DEVICE', device)
    // },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import './assets/css/style.css'

// 全局方法
// import {getRequest} from "@/api/api";
// Vue.prototype.getRequest = getRequest;

import { getQueryRequest,postQueryRequest } from "@/api/index";
Vue.prototype.getRequest = getQueryRequest;
Vue.prototype.postRequest = postQueryRequest;

Vue.prototype.$VUE_APP_API = process.env.VUE_APP_BASE_API;


// 全局组件
import slider from '@/components/common/slide/index';
Vue.component('Slideer', slider)

import sidebar from '@/components/common/sidebar/index';
Vue.component('Sidebar', sidebar)



import Pagination from "@/components/common/Pagination/index";
Vue.component('Pagination', Pagination)



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

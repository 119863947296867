import request from '@/utils/request'




export function getQueryRequest(url,query) {
  return request({
    url: url,
    method: 'get',
    params: query
  })
}


// export function getParamRequest(postId) {
//   return request({
//     url: '/admin/common/appVersion/detail?id=' + postId,
//     method: 'get'
//   })
// }


export function postQueryRequest(url,data) {
  return request({
    url: url,
    method: 'post',
    data: data
  })
}




export function updatePost(data) {
  return request({
    url: '/admin/common/appVersion/edit',
    method: 'post',
    data: data
  })
}


export function delPost(postId) {
  return request({
    url: '/admin/common/appVersion/del',
    method: 'post',
    data:postId
  })
}


import {
    getQueryRequest
} from "@/api/index"; // 获取区域数据

import Cookies from 'js-cookie'

const state = {
    slideList:Cookies.get('slideList')?JSON.parse(Cookies.get('slideList')):[] || []
}

const mutations = {
    SET_SLIDE: (state, data) => {
        state.slideList = data;
        Cookies.set('slideList', JSON.stringify(data))
    }
}

const actions = {
    
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}